<template>
  <div class="error" style="background-image: url(/img/login/login-bg.jpg);">
    <div class="container">
      <div class="error__inner">
        <a class="error__logo" href="#">
          <img src="/img/login/logo-mirrored.svg" alt="Логотип">
        </a>
        <div class="error__content">
          <h1 class="error__title">
            Упс...
          </h1>
          <p class="error__text">
            Не удалось найти запрашиваемую страницу :(
          </p>
          <router-link to="/" class="btn btn--primary-light btn--large login__submit">
            На главную
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
